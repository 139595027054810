import * as anchor from '@project-serum/anchor';
import { WalletContextState } from "@solana/wallet-adapter-react";
import MintBooster from '../../components/mints/mint-booster/mint-booster';

const Boosters = ({ wallet }: { wallet: WalletContextState }) => {
    const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
    const connection = new anchor.web3.Connection(
        rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
    );

    const boosters = [
        { image: "/MAGIC_SOL.png", booster_cm_id: process.env.REACT_APP_MAGIC_SOL_CM_ID, token: "SOL" },
        { image: "/MAGIC_GEM.png", booster_cm_id: process.env.REACT_APP_MAGIC_GEMS_CM_ID, token: "GEMS" },
        { image: "/VOYAGER_SOL.png", booster_cm_id: process.env.REACT_APP_VOYAGER_SOL_CM_ID, token: "SOL" },
        { image: "/VOYAGER_GEM.png", booster_cm_id: process.env.REACT_APP_VOYAGER_GEMS_CM_ID, token: "GEMS" },
    ];

    return (
        <div className="relative w-full min-h-screen bg-black overflow-x-hidden">
            <div className="w-full flex justify-center items-center px-8 sm:px-16 sm:py-6">
                <div className="w-full h-auto bg-gradient-to-b from-violet-500 via-pink-500 to-orange-300 p-1">
                    <img className="w-full h-auto" src="/website_wp_01_july.png" alt="booster promo" />
                </div>
            </div>
            <div className="w-full h-full text-white p-8 flex flex-row flex-wrap justify-around items-center">
                {
                    boosters.map(booster => (
                        <div key={booster.booster_cm_id} className={`w-full sm:w-80 flex flex-col p-4 justify-center items-center border-2 my-4 ${booster.token === "SOL" ? "border-violet-500" : "border-orange-300"}`}>
                            <MintBooster boosterInfo={booster} connection={connection} wallet={wallet} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Boosters;