
import { clusterApiUrl } from '@solana/web3.js';
import Main from "../main/main";
import { useMemo } from "react";

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  //@ts-ignore
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
import './App.scss';
import { ThemeProvider, createTheme } from '@material-ui/core';
const theme = createTheme({
  palette: {
    type: 'dark',
  },
});
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(() => (
    [getPhantomWallet(), getSolflareWallet(), getSolletWallet({ network })]
  ), []);

  return (
    <div className="w-full absolute t-0 l-0 overflow-x-hidden">
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletDialogProvider>
              <Main />
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
