import ConnectButton from "../../buttons/connect-button/connect-button";
import { Connection } from "@solana/web3.js";
import { formatNumber } from "../../buttons/mint-button/utils";
import { getCandyMachineState } from "../../buttons/mint-button/candy-machine"
import MintButton from "../../buttons/mint-button/mint-button";
import { useEffect, useMemo, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';

const MintBooster = ({ boosterInfo, connection, wallet }: { boosterInfo: { image: string, booster_cm_id: string | undefined, token: string }, connection: Connection, wallet: WalletContextState }) => {
    const [candyMachine, setCandyMachine] = useState<any>();

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    useEffect(() => {
        const fetchCandyMachineInfo = async () => {
            if (anchorWallet && boosterInfo.booster_cm_id) {
                const candyMachineId = new anchor.web3.PublicKey(
                    boosterInfo.booster_cm_id,
                );
                let cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
                setCandyMachine(cndy);
            }
        }

        fetchCandyMachineInfo();
    }, [anchorWallet, boosterInfo, connection])

    if (boosterInfo.booster_cm_id) {
        return (
            <div className="flex flex-col justify-center items-center">
                <img className="w-full h-96 object-cover border-2 border-gray-500 p-2 rounded" src={boosterInfo.image} alt={boosterInfo.image} />
                <div className="flex flex-row whitespace-nowrap justify-between my-2">
                    {
                        candyMachine && candyMachine?.state &&
                        <>
                            <p>
                                {`Remaining: ${candyMachine.state.itemsRemaining}`}
                            </p>
                            <p className="mx-2">|</p>
                            <p>
                                {`Edition: ${candyMachine.state.itemsAvailable}`}
                            </p>
                            <p className="mx-2">|</p>
                            <p>
                                {`${formatNumber.asNumber(candyMachine.state.price)} ${boosterInfo.token}`}
                            </p>
                        </>
                    }
                </div>
                <div className="m-2">
                    {
                        !wallet || !wallet.connected ?
                            <ConnectButton color={boosterInfo.token === "SOL" ? "#8B5CF6" : "#fb923c"} />
                            :
                            <MintButton candyMachineIdString={boosterInfo.booster_cm_id} color={boosterInfo.token === "SOL" ? "violet-500" : "orange-400"} isPromo={false} wallet={wallet} />
                    }
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default MintBooster;
