import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import "@solana/wallet-adapter-react-ui/styles.css";

const ConnectButton = ({ color }: { color?: string | undefined }) => {
    const defaultColor = "#fb923c";
    
    return (
        <WalletDialogButton className="w-24 py-1" style={{ backgroundColor: color || defaultColor, borderRadius: "30px", color: "white" }}>
            Connect
        </WalletDialogButton>
    )
}

export default ConnectButton;